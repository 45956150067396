import moment from "moment";

export const trimAddress = (addr) => {
  return `${addr.substring(0, 6)}...${addr.substring(addr.length - 4)}`;
}


export const formatDate = (unixTime) => {
  try {
    let dateString = moment(unixTime).format('lll');
    return dateString;
  }
  catch (err) {
    console.log(err.message);
  }
}

export const contract = {
  
  5: {
    MULTICALL_ADDRESS: "0xa78b1c073483655dc5ecf568cfef12ab9b30338c",
    CARBON_ADDRESS: "0x2047193cbb71687ec747597c490e31158778093b",
    WETH_ADDRESS: "0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6",
    CARBON_LP_ADDRESS: "0x9fb20919a779a819a096fdc441d1f93925194722",
    WETH_USD_LP_ADDRESS: "0x00B64e468d2C705A0907F58505536a6C8c49Ab26",
    DAO_ADDRESS: "0x37702d35BbC100cC84F6ca770839F5749b812c93",
    NFT_ADDRESS: "0x3b335205B0c34417D02727B01b6180a9DAF2Ff26"
  }
}

export const DEFAULT_CHAIN = 5;
export const ALCHEMY_API_KEY = '7ALHbqE6c7NLDD9q4chDf0QBoRvq6s22';
export const ALCHEMY_BASE_URL = `https://eth-goerli.g.alchemy.com/v2/${ALCHEMY_API_KEY}/getOwnersForCollection/`;
export const IPFS_URL = 'https://ipfs.moralis.io:2053/ipfs/';
export const ALCHEMY_BASE_URL_WALLET = `https://eth-goerli.g.alchemy.com/v2/${ALCHEMY_API_KEY}/getNFTs/`;
